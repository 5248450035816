import React, { ElementType, forwardRef, Ref, useEffect } from 'react';
import styled from 'styled-components';
import {
  Box,
  CI,
  HStack,
  Icongraphy,
  NumberBadge,
  Text,
  VStack,
} from '@design-system/pc';
import { ElementProps } from '@/@types/utils';
import { GNB_TYPE_VALUE } from '@/constants';
import useHeader from './hooks/useHeader';
import GnbSearchInput from '@/ui/GnbSearchInput';
import CategoryMenu from './CategoryMenu';
import {
  HEADER_HEIGHT,
  LAYOUT_MAX_WIDTH,
  HEADER_Z_INDEX,
  theme,
} from '@/styles';
import TrackingService from '@/services/tracking';
import { cartGateway } from '@/di-container/common/cart/gateway/client';
import useAuthenticated from '@/hooks/useAuthenticated';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentCartCount,
  setCurrentCartCount,
} from '@/redux/reducers/header';

interface IHeaderProps {
  isGnbExist?: boolean;
  isBackgroundColor?: boolean;
  isWelcomePage?: boolean;
}

type HeaderPropsType<T extends ElementType> = ElementProps<T> & IHeaderProps;

const Header = <T extends ElementType>(
  // eslint-disable-next-line no-empty-pattern
  { isBackgroundColor = true, isWelcomePage = false }: HeaderPropsType<T>,
  ref?: Ref<any>,
) => {
  const {
    handleRouter,
    isBackgroundColorActive,
    setIsHeaderMouseEnter,
    mallSectionMenu,
    interiorSectionMenu,
    isShowMallCategory,
    setIsShowMallCategory,
    isShowInteriorCategory,
    setIsShowInteriorCategory,
    isShowSearchInput,
    handleIsShowSearchInput,
    enteredIcon,
    setEnteredIcon,
    filteredHeaderMenu,
  } = useHeader({ isBackgroundColor, isWelcomePage });

  const { getCount } = cartGateway();
  const { custNo } = useAuthenticated();

  const dispatch = useDispatch();

  const currentCartCnt = useSelector(selectCurrentCartCount);

  useEffect(() => {
    getCount.exec({ custNo }).then((apiResponse) => {
      if (apiResponse.code === 200) {
        const cartCount = apiResponse.data?.cartCnt || 0;
        dispatch(setCurrentCartCount(cartCount));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FakeHeader ref={ref} />
      <HeaderWrapper
        isBackgroundColorActive={isBackgroundColorActive}
        onMouseEnter={() => {
          setIsHeaderMouseEnter(true);
        }}
        onMouseLeave={() => {
          setIsHeaderMouseEnter(false);
        }}
        {...TrackingService.tagGTM('header')}
      >
        <HeaderContainer
          backgroundColor={
            isBackgroundColorActive ? 'color_chip.white' : 'transparent'
          }
          isWelcomePage={isWelcomePage}
        >
          <HeaderWrap alignment="center" space="center">
            <LeftWrap
              space={40}
              alignment="center"
              height="100%"
              isWelcomePage={isWelcomePage}
            >
              <HomeLogo
                onClick={() => handleRouter(GNB_TYPE_VALUE.HOME)}
                {...TrackingService.tagGTM('header_left_icon_button', {
                  'menu-value': GNB_TYPE_VALUE.HOME.toLowerCase(),
                })}
              >
                {isWelcomePage ? (
                  <Box width={231} height={22} />
                ) : (
                  <CI
                    variant={
                      isBackgroundColorActive
                        ? 'CI_signature_black'
                        : 'CI_signature_white'
                    }
                    resize
                    width={231}
                    height={22}
                  />
                )}
              </HomeLogo>
              <HStack space={40} height="100%">
                <VStack
                  height="100%"
                  space="center"
                  onMouseEnter={() => setIsShowMallCategory(true)}
                  onMouseLeave={() => {
                    setIsShowMallCategory(false);
                  }}
                >
                  <VStack position="relative">
                    <HeaderNavText
                      onClick={() => handleRouter(GNB_TYPE_VALUE.MALL)}
                      color={
                        isBackgroundColorActive
                          ? 'color_chip.primary'
                          : 'color_chip.white'
                      }
                      variant="label_xlg_m"
                      {...TrackingService.tagGTM('header_left_icon_button', {
                        'menu-value': GNB_TYPE_VALUE.MALL.toLowerCase(),
                      })}
                    >
                      홈퍼니싱
                    </HeaderNavText>
                    <CategoryMenu
                      sectionMenuData={mallSectionMenu}
                      isShow={isShowMallCategory}
                    />
                  </VStack>
                </VStack>
                <VStack
                  height="100%"
                  space="center"
                  onMouseEnter={() => setIsShowInteriorCategory(true)}
                  onMouseLeave={() => {
                    setIsShowInteriorCategory(false);
                  }}
                >
                  <VStack position="relative">
                    {/* 2024.6.10 기존의 홈아이디어 이름이 인테리어로 변경됨 */}
                    <HeaderNavText
                      onClick={() => handleRouter(GNB_TYPE_VALUE.INTERIOR)}
                      color={
                        isBackgroundColorActive
                          ? 'color_chip.primary'
                          : 'color_chip.white'
                      }
                      variant="label_xlg_m"
                      {...TrackingService.tagGTM('header_left_icon_button', {
                        'menu-value': GNB_TYPE_VALUE.INTERIOR.toLowerCase(),
                      })}
                    >
                      인테리어
                    </HeaderNavText>
                    <CategoryMenu
                      sectionMenuData={interiorSectionMenu}
                      isShow={isShowInteriorCategory}
                    />
                  </VStack>
                </VStack>
              </HStack>
            </LeftWrap>

            <RightWrap>
              <Box position="relative">
                <GnbSearchInput
                  placeholder="검색어를 입력해 주세요."
                  isBackgroundColorActive={isBackgroundColorActive}
                  isWelcomePage={isWelcomePage}
                  isShowSearchInput={isShowSearchInput}
                  handleIsShowSearchInput={handleIsShowSearchInput}
                />
              </Box>
              <RightIconArea space={24}>
                {filteredHeaderMenu.map((_menuItem, index) => {
                  return (
                    <IconTextArea
                      key={_menuItem.key}
                      alignment="center"
                      isShow={
                        !(_menuItem.key === 'SEARCH' && isShowSearchInput)
                      }
                      ml={index === 0 && !isWelcomePage ? 28 : 0}
                      onMouseEnter={() => setEnteredIcon(_menuItem.key)}
                      onMouseLeave={() => setEnteredIcon('')}
                      onClick={() => handleRouter(_menuItem.key)}
                      {...TrackingService.tagGTM('header_right_icon_button', {
                        'menu-value': _menuItem.key.toLowerCase(),
                      })}
                    >
                      {_menuItem.key === 'SEARCH' && (
                        <Icongraphy
                          variant="ic_search"
                          fillColor={isBackgroundColorActive ? '' : 'white'}
                          resize
                          size={28}
                        />
                      )}
                      {_menuItem.key === 'LOGIN' && (
                        <Icongraphy
                          variant="ic_login"
                          fillColor={isBackgroundColorActive ? '' : 'white'}
                          resize
                          size={28}
                        />
                      )}
                      {_menuItem.key === 'LOGOUT' && (
                        <Icongraphy
                          variant="ic_logout"
                          fillColor={isBackgroundColorActive ? '' : 'white'}
                          resize
                          size={28}
                        />
                      )}
                      {_menuItem.key === 'MY' && (
                        <Icongraphy
                          variant="ic_my"
                          fillColor={isBackgroundColorActive ? '' : 'white'}
                          resize
                          size={28}
                        />
                      )}
                      {_menuItem.key === 'SHOP_SEARCH' && (
                        <Icongraphy
                          variant="ic_location"
                          fillColor={isBackgroundColorActive ? '' : 'white'}
                          resize
                          size={28}
                        />
                      )}
                      {_menuItem.key === 'CART' && (
                        <NumberBadge
                          count={currentCartCnt}
                          offset={{
                            right: -7,
                            top: 10,
                          }}
                        >
                          <Icongraphy
                            variant="ic_shopping"
                            fillColor={isBackgroundColorActive ? '' : 'white'}
                            resize
                            size={28}
                          />
                        </NumberBadge>
                      )}
                      <IconText
                        variant="label_sm_m"
                        color={
                          isBackgroundColorActive
                            ? 'color_chip.primary'
                            : 'color_chip.white'
                        }
                        mt="8px"
                        isShow={!isWelcomePage || _menuItem.key === enteredIcon}
                      >
                        {_menuItem.value}
                      </IconText>
                    </IconTextArea>
                  );
                })}
              </RightIconArea>
            </RightWrap>
          </HeaderWrap>
        </HeaderContainer>
      </HeaderWrapper>
    </>
  );
};

export default forwardRef(Header) as typeof Header;

const HomeLogo = styled(Box)`
  cursor: pointer;
`;

const HeaderWrapper = styled.header<{
  isBackgroundColorActive: boolean;
}>`
  z-index: ${HEADER_Z_INDEX};
  background-color: ${({ isBackgroundColorActive }) =>
    isBackgroundColorActive
      ? `${theme.colors.neutral_colors.white}`
      : 'transparent'};
  position: fixed;
  justify-content: space-between;
  width: 100%;
  transition: background-color 0.2s;
`;

const HeaderContainer = styled(Box)<{ isWelcomePage: boolean }>`
  position: relative;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid;
  border-color: ${({ isWelcomePage }) =>
    isWelcomePage
      ? `transparent`
      : `${theme?.colors.neutral_colors.gray?.[100]}`};
  z-index: ${HEADER_Z_INDEX};
`;

const HeaderWrap = styled(HStack)`
  width: ${LAYOUT_MAX_WIDTH}px;
  height: ${HEADER_HEIGHT}px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

const LeftWrap = styled(HStack)<{ isWelcomePage: boolean }>`
  width: 437px;
  visibility: ${({ isWelcomePage }) => (isWelcomePage ? `hidden` : 'visible')};
`;

const HeaderNavText = styled(Text)`
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
`;

const RightWrap = styled(Box)`
  position: relative;
  display: flex;
`;

const RightIconArea = styled(HStack)``;

const IconTextArea = styled(VStack)<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? `flex` : 'none')};
  width: 42px;
  align-items: center;
  cursor: pointer;
`;

const IconText = styled(Text)<{ isShow: boolean }>`
  visibility: ${({ isShow }) => (isShow ? `visible` : 'hidden')};
`;

const FakeHeader = styled(Box)`
  height: ${HEADER_HEIGHT}px;
  position: absolute;
`;
