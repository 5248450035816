import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useStore } from 'react-redux';
import { throttle } from 'lodash';

import useAuthenticated from '@/hooks/useAuthenticated';
import { authGateway } from '@/di-container/auth/gateway/client';
import { DisplayViewType, GetMenusRequest } from '@store/content';
import { GNB_TYPE_VALUE, HEADER_MENU } from '@/constants';
import gnbSlice from '@/redux/reducers/gnb';
import { displayGateway } from '@/di-container/display/gateway/client';
import useSWR from 'swr';

export interface UseHeader {
  isBackgroundColor?: boolean;
  isWelcomePage?: boolean;
}

const useHeader = (props?: UseHeader) => {
  const { isBackgroundColor = true, isWelcomePage = false } = props ?? {};
  const router = useRouter();
  const { pathname } = router;
  const store = useStore();
  const { isAuthenticated } = useAuthenticated();
  const { login, logout } = authGateway(store.dispatch);

  const [isGnbShow, setIsGnbShow] = useState<boolean>(true);
  const [isTransparentBackground, setIsTransparentBackground] =
    useState<boolean>(!isBackgroundColor);
  const [viewType, setViewType] = useState<DisplayViewType>(
    GNB_TYPE_VALUE.MALL,
  );
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMenuTitleEnter, setIsMenuTitleEnter] = useState(false);
  const [isHeaderMouseEnter, setIsHeaderMouseEnter] = useState(false);

  const [isShowMallCategory, setIsShowMallCategory] = useState(false);
  const [isShowInteriorCategory, setIsShowInteriorCategory] = useState(false);

  const [isShowSearchInput, setIsShowSearchInput] = useState(false);
  const [enteredIcon, setEnteredIcon] = useState('');

  const filteredHeaderMenu = useMemo(() => {
    return HEADER_MENU.filter((item) => {
      if (!isWelcomePage && item.key === 'SEARCH') {
        return false;
      }
      if (isAuthenticated && item.key === 'LOGIN') {
        return false;
      }
      if (!isAuthenticated && item.key === 'LOGOUT') {
        return false;
      }
      return true;
    });
  }, [isAuthenticated, isWelcomePage]);

  const isBackgroundColorActive = useMemo(() => {
    return (
      !isWelcomePage &&
      (isBackgroundColor || isHeaderMouseEnter || !isTransparentBackground)
    );
  }, [
    isWelcomePage,
    isBackgroundColor,
    isHeaderMouseEnter,
    isTransparentBackground,
  ]);

  const isGnbDefaultShow = useMemo(() => {
    if (
      pathname === '/my/consult' ||
      pathname === '/my/visit-consult' ||
      pathname === '/my/budget-expect' ||
      pathname === '/shop/main-search'
    ) {
      return false;
    } else return true;
  }, [pathname]);

  const handleRouter = useCallback(
    async (value: keyof typeof GNB_TYPE_VALUE) => {
      switch (value) {
        case 'HOME': {
          router.push(`/`);
          break;
        }
        case 'MALL': {
          router.push(
            `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mall.html`,
          );
          break;
        }
        case 'SEARCH': {
          handleIsShowSearchInput(true);
          break;
        }
        case 'INTERIOR': {
          router.push(
            `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/interior.html`,
          );
          break;
        }
        case 'SHOP_SEARCH': {
          router.push(
            `${process.env.NEXT_PUBLIC_REMODELING_WEB_HOST}/shop/search`,
          );
          break;
        }
        case 'CART': {
          router.push(
            `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/order/goCart.do?util=shopping`,
          );
          break;
        }
        case 'LOGIN': {
          await login.exec();
          break;
        }
        case 'JOIN': {
          // handleOpenJoinPopup();
          router.push(
            `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/customer/newMembershipCerti.do?util=join`,
          );
          break;
        }
        case 'LOGOUT': {
          await logout.exec();
          break;
        }
        case 'MY': {
          isAuthenticated
            ? router.push(
                `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/mypageMain.do?util=my`,
              )
            : router.push(
                `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/mypageMainNonmember.do?util=my`,
              );
          break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, login, logout, router],
  );

  const handleIsMenuTitleEnter = () => {
    if (
      pathname === '/my/consult' ||
      pathname === '/my/visit-consult' ||
      pathname === '/shop/main-search' ||
      pathname === '/my/budget-expect'
    ) {
      store.dispatch(gnbSlice.actions.setIsHeaderShow(true));
    }
    setIsMenuTitleEnter(true);
  };

  const handleIsMenuTitleLeave = () => {
    setIsMenuTitleEnter(false);
  };

  const handleIsShowSearchInput = useCallback((isShow: boolean) => {
    setIsShowSearchInput(isShow);
  }, []);

  /** GNB showing 스크롤 이벤트 */
  useEffect(() => {
    let lastScrollY = 0;
    const handleHeaderScroll = throttle(() => {
      const scrollY = window.scrollY;
      // 이전의 스크롤 위치와 비교하기
      const direction: 'DOWN' | 'UP' = scrollY > lastScrollY ? 'DOWN' : 'UP';
      // 현재의 스크롤 값을 저장
      lastScrollY = scrollY;

      setScrollPosition(lastScrollY);

      if (direction === 'DOWN') {
        store.dispatch(gnbSlice.actions.setIsHeaderShow(false));
        setIsGnbShow(false);
      } else if (direction === 'UP') {
        if (
          pathname !== '/my/consult' &&
          pathname !== '/my/visit-consult' &&
          pathname !== '/my/budget-expect' &&
          pathname !== '/shop/main-search'
        ) {
          store.dispatch(gnbSlice.actions.setIsHeaderShow(true));
          setIsGnbShow(true);
        }
      }
    }, 100);

    window.addEventListener('scroll', handleHeaderScroll);
    return () => window.removeEventListener('scroll', handleHeaderScroll);
  }, [isGnbDefaultShow, pathname, store]);

  useEffect(() => {
    const handleTranslateBackground = () => {
      if (!isBackgroundColor) {
        setIsTransparentBackground(true);
      }

      if (window.scrollY === 0) {
        setIsTransparentBackground(true);
      } else {
        setIsTransparentBackground(false);
      }
    };
    window.addEventListener('scroll', handleTranslateBackground);
    return () =>
      window.removeEventListener('scroll', handleTranslateBackground);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getHamburgerMenus } = displayGateway();

  const menuFetcher = (req: GetMenusRequest) => {
    return getHamburgerMenus.exec(req);
  };

  const { data: categoryApiResponseData } = useSWR(
    { gateway: 'menus', typeValue: 'PC', viewType: 'ALL' },
    menuFetcher,
  );

  const sectionMenuList = useMemo(() => {
    return categoryApiResponseData?.code === 200
      ? categoryApiResponseData?.data?.sectionMenuList || []
      : undefined;
  }, [categoryApiResponseData]);

  const mallSectionMenu = useMemo(() => {
    if (sectionMenuList) {
      return sectionMenuList.find((item) => item.mallType === 'MALL');
    } else {
      return null;
    }
  }, [sectionMenuList]);

  const interiorSectionMenu = useMemo(() => {
    if (sectionMenuList) {
      return sectionMenuList.find((item) => item.mallType === 'REMODELING');
    } else {
      return null;
    }
  }, [sectionMenuList]);

  return {
    scrollPosition,
    isGnbShow,
    setIsGnbShow,
    handleRouter,
    viewType,
    setViewType,
    isBackgroundColorActive,
    isGnbDefaultShow,
    isMenuTitleEnter,
    handleIsMenuTitleEnter,
    setIsHeaderMouseEnter,
    handleIsMenuTitleLeave,
    mallSectionMenu,
    interiorSectionMenu,
    isShowMallCategory,
    setIsShowMallCategory,
    isShowInteriorCategory,
    setIsShowInteriorCategory,
    isShowSearchInput,
    setIsShowSearchInput,
    handleIsShowSearchInput,
    enteredIcon,
    setEnteredIcon,
    filteredHeaderMenu,
  };
};

export default useHeader;
